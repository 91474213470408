import React, {useState} from 'react'

import XIcon from '../shared/icons/xIcon.jsx'

export default function TutorialModal({open, close, retailers, brands}) {

  const [page, setPage] = useState(1)

  function TutorialContent() {

    switch (page) {
    case 1:
      return <TutPg1 />
    case 2:
      return <TutPg2 />
    case 3:
      return <TutPg3 />
    case 4:
      return <TutPg4 />
    case 5:
      return <TutPg5 />
    case 6:
      return <TutPg6 setPage={setPage} retailers={retailers} brands={brands} />
    case 7:
      return <TutPg7 />
    default:
      return null
    }
  }

  function handleModalClose() {
    close()
    setPage(1)
  }

  return <div className={`z-50 fixed top-0 right-0 bottom-0 left-0 overflow-auto w-full h-full bg-black bg-opacity-80`} data-visible={open}>
    <div className="flex items-center justify-center w-full h-full">
      <div className="bg-white text-dark">
        <div className="flex bg-primary text-white p-4 font-bold relative">
          <h2 className="md:w-full mr-8 text-xl font-accent">
            {page == 7 ? <span>Brand Website Tutorial</span> : <span>Getting Started ({page}/6)</span>}
          </h2>

          <div className="text-white ml-auto">
            <button onClick={ handleModalClose }>
              <XIcon />
            </button>
          </div>
        </div>

        <div className="overflow-auto p-4 mx-auto" style={{height: '70vh', width: '90vw', maxHeight:'450px', maxWidth: '400px'}}>
          <TutorialContent />
        </div>

        <div className='bg-gray-100 w-full p-4'>
          {page == 1 ? <button className='block ml-auto px-2 py-1.5 border border-light rounded-full hover:shadow bg-primary text-white font-accent' onClick={ ()=>setPage(page + 1) }>Next</button>: <></>}

          {page > 1 && page < 6 ? <div className='flex justify-between'>
            <button className='px-2 py-1.5 border border-light rounded-full hover:shadow bg-primary text-white font-accent' onClick={ ()=>setPage(page - 1) }>Back</button>

            <button className='px-2 py-1.5 border border-light rounded-full hover:shadow bg-primary text-white font-accent' onClick={ ()=>setPage(page + 1) }>Next</button>
          </div> : <></>}

          {page == 6 ? <div className='flex justify-between'>
            <button className='px-2 py-1.5 border border-light rounded-full hover:shadow bg-primary text-white font-accent' onClick={ ()=>setPage(page - 1) }>Back</button>

            <button className='px-2 py-1.5 border border-light rounded-full hover:shadow bg-primary text-white font-accent' onClick={ handleModalClose }>Finish</button>
          </div> : <></>}

          {page == 7 ? <button className='px-2 py-1.5 border border-light rounded-full hover:shadow  bg-primary text-white font-accent' onClick={ ()=>setPage(page - 1) }>Back</button> : <></>}
        </div>
      </div>
    </div>
  </div>
}

function TutPg1() {
  return <div>
    <h1 className='mb-xs text-base font-bold'>Find digital rebates on your favorite beer, wine, and spirits</h1>

    <p>Discover where and how to get cash back through any retailer</p>

    <img src='/assets/images/offers/tutorial/1.png' className='w-36 md:h-96 md:w-auto mx-auto mt-4'/>
  </div>
}

function TutPg2() {
  return <div>
    <h1 className='mb-xs text-base font-bold'>Allow access to your location or select your state to get started</h1>

    <p>Available offers are determined based on the state you are in. We do not collect nor sell any user data</p>

    <img src='/assets/images/offers/tutorial/2.png' className='mx-auto mt-4 w-80'/>
  </div>
}

function TutPg3() {
  return <div>
    <h1 className='mb-xs text-base font-bold'>Browse and view offers</h1>

    <p>Select an offer to see ways to redeem</p>

    <img src='/assets/images/offers/tutorial/3.png' className='mx-auto mt-4 border border-light' style={{maxHeight: '345px'}}/>
  </div>
}

function TutPg4() {
  return <div>
    <h1 className='mb-xs text-base font-bold'>Where to redeem</h1>

    <p>Have a go-to retailer or brand? You can use the filter to find offers that are available there</p>

    <img src='/assets/images/offers/tutorial/4.png' className='mx-auto mt-4 border border-light' style={{maxHeight: '345px'}}/>
  </div>
}

function TutPg5() {
  return <div>
    <h1 className='mb-xs text-base font-bold'>All offers are available in the BYBE app</h1>

    <p>Every offer is available to you no matter where you shop</p>

    <img src='/assets/images/offers/tutorial/bybe_logo.svg' className='mx-auto mt-4 p-18'/>
  </div>
}

const TutPg6 = ({setPage, retailers, brands}) => {

  const getRetailerURL = (retailer) => {
    if (retailer.name == 'BYBE') {
      return 'https://app.bybeapp.com/'
    } else {
      return `https://${retailer.payout_subdomain.toLowerCase()}.bybe.io/how-to`
    }
  }

  function showBrandSiteTutorial() {
    setPage(7)
  }

  return <div>
    <img src='/assets/images/offers/tutorial/6.png' className='mx-auto mb-4 h-40'/>

    <h1 className='text-base font-bold'>Learn more</h1>

    <p className="mb-5">Select a retailer or brand below to learn how to redeem</p>

    <div className='flex gap-3 flex-wrap items-center justify-center'>
      {retailers.map((retailer) => {
        return <a key={`tutorialRetailer-${'retailer.id'}`} href={retailer.name == 'BYBE' ? 'https://app.bybeapp.com/' : `https://${retailer.payout_subdomain.toLowerCase()}.bybe.io/how-to`}>
          <img src={retailer.image_url} style={{width: '48px'}} />
        </a>
      })}

      {brands.map((brand, index) => {
        return <a key={index} onClick={showBrandSiteTutorial}>
          <img src={brand.logo_image_url} style={{width: '48px'}} />
        </a>
      })}
    </div>
  </div>
}

function TutPg7() {
  return <div>
    <section className="p-xs">
      <div className="flex space-x-2 mb-xs">
        <div className="w-6 h-6 flex justify-center items-center mb-xs rounded-full bg-gray-300 flex-none">1</div>

        <p>Create account or login via email link</p>
      </div>
      <img src="/assets/images/offers/tutorial/brands/1.jpg" className="w-48 mx-auto mb-2 border border-gray-300" alt="Send login link to email" />
      <img src="/assets/images/offers/tutorial/brands/2.jpg" className="w-48 mx-auto mb-2 border border-gray-300" alt="Send login link to email" />
      <img src="/assets/images/offers/tutorial/brands/3.jpg" className="w-48 mx-auto" alt="Send login link to email" />
    </section>

    <section className="p-xs">
      <div className="flex space-x-2 mb-xs">
        <div className="w-6 h-6 flex justify-center items-center mb-xs rounded-full bg-gray-300 flex-none">2</div>

        <p>Verify your phone number</p>
      </div>
      <img src="/assets/images/offers/tutorial/brands/4.jpg" className="w-48 mx-auto border border-gray-300" alt="Send login link to email" />
    </section>

    <section className="p-xs">
      <div className="flex space-x-2 mb-xs">
        <div className="w-6 h-6 flex justify-center items-center mb-xs rounded-full bg-gray-300 flex-none">3</div>

        <p>Upload and submit your receipt. It can take up to 3 days for your receipt to be approved</p>
      </div>
      <img src="/assets/images/offers/tutorial/brands/5.jpg" className="w-48 mx-auto border border-gray-300" alt="Send login link to email" />
    </section>

    <section className="p-xs">
      <div className="flex space-x-2 mb-xs">
        <div className="w-6 h-6 flex justify-center items-center mb-xs rounded-full bg-gray-300 flex-none">4</div>

        <p>Once approved, log in to your account by clicking on the link in the email</p>
      </div>
      <img src="/assets/images/offers/tutorial/brands/6.jpg" className="w-48 mx-auto border border-gray-300" alt="Send login link to email" />
    </section>

    <section className="p-xs">
      <div className="flex space-x-2 mb-xs">
        <div className="w-6 h-6 flex justify-center items-center mb-xs rounded-full bg-gray-300 flex-none">5</div>

        <p>Click on your balance and cash out via Paypal, Venmo, or Visa</p>
      </div>
      <img src="/assets/images/offers/tutorial/brands/7.jpg" className="w-48 mx-auto mb-2 border border-gray-300" alt="Send login link to email" />
      <img src="/assets/images/offers/tutorial/brands/8.jpg" className="w-48 mx-auto border border-gray-300" alt="Send login link to email" />
    </section>

    <section className="p-xs">
      Questions? Reach out to <a href="mailto:abo@bybe.com" className="font-bold">abo@bybe.com</a>
    </section>

  </div>
}